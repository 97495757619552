import { mapActions, mapGetters, mapMutations } from "vuex";
import salesInvoice from "@/modules/profile/components/sales-Invoice/index.vue";
import expandedInvoice from "@/modules/profile/components/expanded-invoice/index.vue";
import storageInvoice from "@/modules/profile/components/storage-invoice/index.vue";
import { downloadFileFrom } from "@/helpers";

export default {
    name: "distributor-storage-report",
    data() {
        return {
            brandSelected: [],
            seriesSelected: [],
            countChange: [],
            isOpenBrand: false,
            calculated: false,
            edit: false,
            comment: "",
            sendFormProgress: false,
            orderId: null,
            fileDownloadState: false,
        };
    },
    components: { storageInvoice, expandedInvoice, salesInvoice },
    async created() {
        await this.fetchRemainings();
    },
    methods: {
        ...mapActions({
            fetchRemainings: "remainings/GET_REMAININGS",
            submitData: "remainings/SEND_REMAININGS",
            fetchFile: "documents/GET_DOCUMENT_EXCEL",
            checkOrder: "order/CHECK_ORDER",
        }),

        ...mapMutations({
            showRemainingsPopup: "popups/CHANGE_REMAININGS_POPUP",
        }),

        getItemCount(id) {
            const item = this.countChange.find(item => item.product_price_id === id);
            return item ? item.count : 0;
        },

        getTotalChangedSum() {
            return this.countChange.reduce((sum, item) => sum + item.price * item.count, 0);
        },

        async downloadDocument() {
            this.fileDownloadState = true;
            const response = await this.fetchFile({ id: this.orderId });
            await downloadFileFrom(response.data.path, response.data.name + ".xls");
            this.fileDownloadState = false;
        },

        async sendForm() {
            try {
                this.sendFormProgress = true;
                await this.checkOrder({
                    email: this.user.email,
                    first_name: this.user.name,
                    last_name: this.user.secondName,
                    phone: this.user.phone,
                    products: this.countChange,
                });
                if (!this.orderCheckResult.result) {
                    this.showRemainingsPopup(true);
                    this.sendFormProgress = false;
                    return;
                }
                const response = await this.submitData({ comment: this.comment, products: this.countChange });
                this.orderId = response.data.data.data.order.id;
                this.$toasted.success(response.data.data.message);
                this.sendFormProgress = false;
            } catch (e) {
                throw e;
            }
        },

        incrementCount(id, quantity, price, product) {
            if (this.countChange.find(item => item.product_price_id === id)) {
                const item = this.countChange.find(item => item.product_price_id === id);
                if (item.count >= quantity) return;
                item.count += 1;
            } else {
                this.countChange.push({
                    product_price_id: id,
                    count: 1,
                    quantity: quantity,
                    price: price,
                    product: product,
                });
            }
        },

        decrementCount(id) {
            const item = this.countChange.find(item => item.product_price_id === id);
            if (item && item.count > 0) {
                item.count -= 1;
            }
        },

        removeItem(id) {
            const index = this.countChange.findIndex(item => item.product_price_id === id);
            if (index !== -1) {
                this.countChange.splice(index, 1);
            }
        },

        handleCalculation() {
            this.calculated = true;
        },

        handleProductChange(payload) {
            this.countChange = payload;
        },
    },
    computed: {
        ...mapGetters({
            list: "remainings/list",
            loading: "remainings/loading",
            orderCheckResult: "order/orderCheckResult",
            user: "profile/user",
        }),
    },
};
